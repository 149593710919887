export const getStartedHeroValueProps = [
  {
    data: {
      catchphrase: 'Looking to refinance?',
      text: 'Find the best refinance option for you, plus unbiased expert advice.'
    },
    url: 'refinance',
    cta: 'Get Started'
  },
  {
    data: {
      catchphrase: 'Need preapproval?',
      text: 'Maximize your buying power with a preapproval at a great rate.'
    },
    url: 'preapproval',
    cta: 'Get Preapproved'
  },
  {
    data: {
      catchphrase: 'Already preapproved?',
      text: 'Review easy-to-compare bids to ensure you get a great deal.'
    },
    url: 'purchase',
    cta: 'See Rates'
  },
  {
    data: {
      catchphrase: 'Seller accepted your offer?',
      text: 'Quickly compare offers for hassle-free savings and speedy closing.'
    },
    url: 'accepted-offer',
    cta: 'See Rates'
  }
];
