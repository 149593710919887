import { colors, SingleValueProp } from '@rategravity/marketing-components';
import { createOwnUpComponent, fonts } from '@rategravity/own-up-component-library';
import React from 'react';
import { ankleValueProps } from '../../../data/content/get-started/ankle-value-props';
import { metadata } from '../../../data/metadata/images';

const AnkleWrapper = createOwnUpComponent('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyItems: 'center',
  columnGap: '24px',
  padding: '175px 0px 200px',
  margin: '0px auto',
  maxWidth: '1024px',
  variants: {
    mediumAndDown: {
      gridTemplateColumns: '1fr',
      padding: '75px 10px 100px'
    }
  },
  // Rotating the swoosh above this can cause a gap to form, so:
  marginTop: '-2px'
});

const ValuePropsWrapper = createOwnUpComponent('div', {
  maxWidth: '350px',
  '& .catchphrase > span': {
    ...fonts.TIEMPOS_HEADLINE_REGULAR,
    color: colors.WHITE,
    fontSize: '32px'
  },
  '& .primary-text > span': {
    color: colors.WHITE,
    fontSize: '17px',
    lineHeight: '25px'
  }
});

export const GetStartedAnkle = () => (
  <AnkleWrapper>
    {ankleValueProps.map((valueProp, index) => (
      <ValuePropsWrapper key={index}>
        <SingleValueProp {...valueProp} metadata={metadata} decorativeIcon={true} />
      </ValuePropsWrapper>
    ))}
  </AnkleWrapper>
);
