import React from 'react';
import { UnderlinedLinkInternal } from '../../../components/links';
import chatSVG from '../../../images/icons/chat.svg';
import percentSVG from '../../../images/icons/percent.svg';
import xSVG from '../../../images/icons/x.svg';
import { colors } from '../../../modules/colors';

export const ankleValueProps = [
  {
    data: {
      catchphrase: 'Great rates',
      text: (
        <>
          <span>
            Rates in our marketplace beat 90% of offers out there, which means our customers save{' '}
            <UnderlinedLinkInternal color={colors.WHITE} to="/methodology/">
              $20,000+
            </UnderlinedLinkInternal>{' '}
            over the life of their loan.
          </span>
        </>
      )
    },
    Image: {
      svg: percentSVG,
      imageName: 'percent.svg',
      height: 40
    }
  },
  {
    data: {
      catchphrase: 'No spam',
      text: 'We keep your information completely confidential, with no impact to your credit. Because you should be able to shop for a mortgage hassle-free.'
    },
    Image: {
      svg: xSVG,
      imageName: 'x.svg',
      height: 40
    }
  },
  {
    data: {
      catchphrase: 'On your side',
      text: "Our licensed Home Advisors don't work for a lender. They're here to provide unbiased, personalized advice so you can achieve your unique financial goals."
    },
    Image: {
      svg: chatSVG,
      imageName: 'chat.svg',
      height: 40
    }
  }
];
