export type GetStartedReviewProps = {
  quote: string;
  attribution: string;
};

export const getStartedReviews: GetStartedReviewProps[] = [
  {
    quote: 'Own Up was the best way to find a mortgage that fit my needs.',
    attribution: 'Julie'
  },
  {
    quote:
      'Made the experience wonderful and found us better rates than when we searched on our own.',
    attribution: 'Emily'
  },
  {
    quote: 'No pushy tactics, just real answers.',
    attribution: 'Derrick'
  }
];
