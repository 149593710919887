import {
  getImage,
  GetStartedLink,
  ImageQuery,
  ImageWithContent,
  ShopCTAButton,
  SwooshDivider
} from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { metadata } from '../../../data/metadata/images';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import GreenDotsBlob4SVG from '../../../images/green-dots-blob-4.svg';
import { colors } from '../../../modules/colors';
import { Header } from '../../typography/icon-landing';

const ContentWrapperStyle = createOwnUpStyle({
  marginLeft: '30px',
  variants: {
    smallAndDown: {
      margin: '0 20px 35px 20px'
    }
  }
});

export const ContentWrapper = createOwnUpComponent('div', ContentWrapperStyle);

export const CTAButtonStyle = css`
  display: inline-block;
  margin-top: 20px;
  max-width: 300px;
  background-color: ${colors.LOGO_SHADOW};
  border-color: ${colors.LOGO_SHADOW};
  color: ${colors.WHITE};
  :hover {
    background-color: ${colors.PRIMARY};
    border-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
`;

export const CTAButton = styled(ShopCTAButton)`
  ${CTAButtonStyle}
`;

export const HeroContent = () => {
  const mixpanel = useMixpanel();

  return (
    <ContentWrapper>
      <Header>
        You&apos;re looking for a great mortgage. We&apos;re here to help you find it.
      </Header>
      <OwnUpText>
        Painless comparison shopping, unbiased expert advice, and an average of $902 in interest
        savings per year.
      </OwnUpText>
      <br />
      <GetStartedLink
        discover={`${process.env.GATSBY_DISCOVER}/get-started`}
        mixpanelEvent={{
          hook: mixpanel,
          name: 'Click discover CTA',
          properties: { 'page-location': 'find-your-best-mortgage-mktg02-hero' }
        }}
      >
        <CTAButton>Get Started</CTAButton>
      </GetStartedLink>
    </ContentWrapper>
  );
};

export const contentImageStyle = {
  width: '100%',
  maxWidth: '900px',
  backgroundSize: '60%',
  backgroundPosition: 'top 90% left 90%',
  '& img': {
    borderRadius: '31px'
  },
  variants: {
    mediumAndDown: {
      position: 'relative' as const
    },
    medium: {
      marginLeft: '-50px',
      paddingLeft: '10px',
      backgroundPosition: 'top 85% left 90% !important',
      left: '100px',
      '> div': {
        padding: '70px 0'
      }
    },
    small: {
      marginBottom: '-100px',
      backgroundPosition: 'top 93% left 90% !important',
      left: '50px',
      bottom: '100px'
    },
    xsmall: {
      marginBottom: '-100px',
      backgroundPosition: 'top 90% left 90% !important',
      left: '30px',
      bottom: '80px'
    }
  }
};

export const imgStyle = {
  position: 'relative' as const,
  margin: 'auto',
  borderRadius: '31px',
  maxWidth: '640px'
};

const HeroWrapper = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    variants: {
      medium: {
        marginLeft: '30px'
      }
    }
  })
);

export const HeroImage = ({ data }: { data: ImageQuery }) => {
  return getImage(data, 'new-homeowners.jpg', metadata, imgStyle);
};

export const Hero = ({ children }: { children: PropsWithChildren<{}> }) => {
  const Image = <>{children}</>;
  return (
    <>
      <HeroWrapper>
        <ImageWithContent
          Image={Image}
          imagePosition="right"
          backgroundImage={GreenDotsBlob4SVG}
          flipImageOnMobile={true}
          imageStyle={contentImageStyle}
          rowOnMedium={true}
        >
          <HeroContent />
        </ImageWithContent>
      </HeroWrapper>
      <SwooshDivider
        topColor={colors.AQUA}
        bottomColor={colors.WHITE}
        desktopMaxWidth={1280}
        mobileLateralGutter={0}
      />
    </>
  );
};
