import React from 'react';
import { Helmet } from 'react-helmet';
import { GetStarted } from '../components/get-started';
import { Layout } from '../components/layout';
import { colors } from '../modules/colors';
export const GetStartedPage = () => (
  <Layout>
    <Helmet>
      <style>{`
        body {
          background-color: ${colors.AQUA}
        }
        nav {
          background-color: ${colors.AQUA}
        }`}</style>
    </Helmet>
    <GetStarted />
  </Layout>
);

export default GetStartedPage;
