import { FullWidthField, SwooshDivider } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { colors } from '../../modules/colors';
import { GetStartedAnkle } from './ankle';
import { GetStartedHero } from './hero';
import { GetStartedReviews } from './reviews';

const SwooshDividerWrapperStyle = createOwnUpStyle({
  '>div': {
    transform: 'rotateY(180deg)'
  }
});
const SwooshDividerWrapper = createOwnUpComponent('div', SwooshDividerWrapperStyle);

export const GetStarted = () => {
  return (
    <>
      <FullWidthField backgroundColor={colors.AQUA} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <GetStartedHero />
      </FullWidthField>
      <SwooshDivider
        topColor={colors.AQUA}
        bottomColor={colors.WHITE}
        desktopMaxWidth={1280}
        mobileLateralGutter={0}
      />
      <FullWidthField backgroundColor={colors.WHITE} desktopMaxWidth={1280} mobileLateralGutter={0}>
        <GetStartedReviews />
      </FullWidthField>
      <SwooshDividerWrapper>
        <SwooshDivider
          topColor={colors.WHITE}
          bottomColor={colors.LOGO_SHADOW}
          desktopMaxWidth={1280}
          mobileLateralGutter={0}
        />
      </SwooshDividerWrapper>

      <FullWidthField
        backgroundColor={colors.LOGO_SHADOW}
        desktopMaxWidth={1280}
        mobileLateralGutter={0}
      >
        <GetStartedAnkle />
      </FullWidthField>
    </>
  );
};
